<template>
  <div id="app">
    <div class="app">
      <router-view />
      <Header />
    </div>
    <div class="guide">
      <h1>ぐるりはスマートフォンでご利用になれます。</h1>

      <div v-if="isProduction()">
        <img src="/images/qr-code-contents.png" />
      </div>
      <div v-else>
        <img src="/images/qr-code-development-contents.png" />
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import Header from './components/layouts/Header.vue'
export default {
  components: {
    Header,
  },
  created() {
    // 現在地を地図の中心に設定
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latLng = position.coords
        this.$store.commit('setCenter', {
          lat: latLng.latitude,
          lng: latLng.longitude,
        })

        //現在時刻と現在地をfirebaseに保存
        if (
          location.href == 'https://gururi-db37f.web.app/' ||
          location.href == 'https://gururi-db37f.firebaseapp.com/' ||
          location.href == 'https://gururi-app.com/'
        ) {
          const locateTimeRef = firestore.collection('locationLogs')
          locateTimeRef.add({
            latLng: new firebase.firestore.GeoPoint(
              Number(latLng.latitude),
              Number(latLng.longitude)
            ),
            createdAt: new Date(),
          })
        }
        this.$store.commit('setIsCurrentPosition', true)
      },
      (error) => {
        console.log('ユーザーの現在地の認識 ブロック', error)
      }
    )

    // 史跡一覧を取得
    const firestore = firebase.firestore()
    const historicSitesRef = firestore.collection('historicSites')
    const categorizationRef = firestore.collection('categorization')
    const categoriesRef = firestore.collection('categories')
    historicSitesRef.get().then(async (snapshot) => {
      const historicSites = []
      snapshot.docs.forEach(async (historicSiteDoc) => {
        // // カテゴリー取得
        const categoryNames = []
        await categorizationRef
          .where('historicSiteId', '==', historicSiteDoc.id)
          .get()
          .then(async (snapshot) => {
            snapshot.docs.forEach(async (categorizationDoc) => {
              const categoryId = categorizationDoc.data().categoryId
              await categoriesRef
                .doc(categoryId)
                .get()
                .then((snapshot) => {
                  categoryNames.push(snapshot.data().name)
                })
            })
          })
        if (!historicSiteDoc.data().logicalDeleteFlag) {
          historicSites.push({
            id: historicSiteDoc.id,
            categoryNames: categoryNames,
            ...historicSiteDoc.data(),
          })
        }
      })
      this.$store.commit('setHistoricSites', historicSites)
    })
    // ショップ一覧を取得
    let shops = []
    const shopsRef = firestore.collection('shops')
    shopsRef.get().then(async (snapshot) => {
      snapshot.docs.forEach(async (shopDoc) => {
        shops.push({ id: shopDoc.id, ...shopDoc.data() })
      })
      this.$store.commit('setShops', shops)
    })

    // ログイン状態を監視
    firebase.auth().onAuthStateChanged((user) => {
      // ログイン時のみユーザーの訪問履歴を取得
      if (user) {
        const visitedHistoryCollectionRef = firestore
          .collection('users')
          .doc(user.uid)
          .collection('visitedHistory')
        visitedHistoryCollectionRef
          .get()
          .then((snapshot) => {
            this.$store.commit(
              'setVisitedSpotIds',
              snapshot.docs.map((doc) => doc.data())
            )
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      }
    })
  },
  methods: {
    isProduction() {
      return location.hostname === process.env.VUE_APP_HOSTNAME_MAIN
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* 以下PC使用時(仮) */
.guide {
  display: none;
}
@media (min-width: 767px) {
  .app {
    display: none;
  }
  .guide {
    display: block;
  }
}
/* pc使用時ここまで */
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
/* 現在地アイコンcss */
.current-icon {
  -webkit-animation: blink 2s ease-in-out infinite alternate;
  -moz-animation: blink 2s ease-in-out infinite alternate;
  animation: blink 2s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
/* 現在地アイコンcssここまで */
a {
  text-decoration: none !important;
}
/* l-iconにcssを適用させるためには全体にcssを適用させないといけないらしく仕方なくここに配置 */
.shopIcon {
  width: auto !important;
}
</style>
